import { faCode, faCogs, faGlobe, faHammer, faMailBulk, faQuestion, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export class LayoutIcons {
  public static readonly dashboard = faGlobe;
  public static readonly settings = faCogs;
  public static readonly tools = faHammer;
  public static readonly dev = faCode;
  public static readonly support = faQuestionCircle;

  public static readonly navNotifications = faMailBulk;
}
