import { Component, Input, OnInit } from '@angular/core';
import { AppGlobals } from '@clp/app-globals';
import { ItemBarItem, RouterLinkItemBarItem, TextItemBarItem } from './layout-itembar-items';
import { IsActiveMatchOptions } from '@angular/router';

@Component({
  selector: 'clp-layout-itembar-item-text',
  template: `<li class="list-group-item">{{item.name}}</li>`,
  styles: [
    ':host { align-self: center; }'
  ]
})
export class TextItemBarItemComp {
  @Input() public item!: TextItemBarItem;
}

@Component({
  selector: 'clp-layout-itembar-item-routerlink',
  template: `
 <li class="list-group-item"
  [ngbTooltip]="item.showLabel ? null : item.toolTip"
  placement="right"
  [attr.disabled]="item.disabled"
  [openDelay]="toolTipOpenDelay"
  routerLinkActive="active"
  [routerLinkActiveOptions]="linkOptions"
  [routerLink]="item.routerLink">
      <fa-icon class="mx-2" [icon]="item.icon"></fa-icon><span *ngIf="item.showLabel">{{item.name}}</span>
</li>
`
})
export class RouterLinkItemBarItemComp {
  @Input() public item!: RouterLinkItemBarItem;
  public toolTipOpenDelay = AppGlobals.navToolTipDelayMs;

  public linkOptions: IsActiveMatchOptions = {
    paths: 'subset',
    matrixParams: 'subset',
    fragment: 'ignored',
    queryParams: 'ignored'
  }
}

@Component({
  selector: 'clp-layout-itembar',
  templateUrl: './layout-itembar.comp.html',
  host: {
    '[style.width.px]': 'width',
    '[class.px-3]': 'listDirection == "row"'
  }
})
export class LayoutItemBarComp implements OnInit {
  constructor() { }

  @Input() public width?: number = 60;
  @Input() public toolTipOpenDelay: number = AppGlobals.navToolTipDelayMs;
  @Input() public items: ItemBarItem[] = [];
  @Input() public listDirection: string = 'column';

  ngOnInit() { }

  getRouterLinkItem(item: any): RouterLinkItemBarItem {
    return <RouterLinkItemBarItem>item;
  }

  getAnyItem(item: any) {
    return item;
  }

  public onButtonClick() {
    if(this.width == 150) {
      this.width = 60;
    } else {
      this.width = 150;
    }
  }
}
