import { Injectable, InjectionToken } from '@angular/core';

export const CLP_LAYOUT_SERVICE = new InjectionToken('CLP_LAYOUT_SERVICE');

@Injectable()
export class ClpLayoutService {
  constructor() {

   }

}
