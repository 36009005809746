import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemBarItem, TextItemBarItem } from '../layout/layout-itembar-items';

@Injectable()
export class UContainerSvc {
  private _items: ItemBarItem[] = [
    new TextItemBarItem('no title')
  ];

  public get items(): ItemBarItem[] {
    return this._items;
  }

  public setTitle(title: string) {
    this._items[0] = new TextItemBarItem(title);
  }

  public setItems(items: ItemBarItem[]){
    const titleItem = this.items.slice(0,1);
    const newItems = [
      ...titleItem,
      ...items
    ];
    this._items = newItems;
  }
}

@Component({
  selector: 'clp-u-container',
  template: `
<clp-layout-itembar [items]="svc.items" [width]="-1" class="shade-2" style="min-height: 48px;" listDirection="row">
</clp-layout-itembar>
<router-outlet></router-outlet>`,
  styles: [
    ':host { flex-direction: column !important; }'
  ],
  providers: [
    UContainerSvc
  ]
})

export class UContainerComp implements OnInit {

  constructor(private ar: ActivatedRoute, public svc: UContainerSvc) {
    const data = ar.snapshot.data;
    this.svc.setTitle(data.title);
  }

  ngOnInit() { }
}
