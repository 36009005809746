import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[scrollToBottom]', exportAs: 'scrollToBottom' })
export class ScrollToBottomDirective {

  isAtBottom: boolean = false;
  top: number = 0;
  offSetHeight: number = 0;
  scrollHeight: number = 0;
  constructor(private eleRef: ElementRef) { }

  @HostListener('scroll') onScrollEvent(event: Event) {
    this.top = this.eleRef.nativeElement.scrollTop;
    this.offSetHeight = this.eleRef.nativeElement.offsetHeight;
    this.scrollHeight = this.eleRef.nativeElement.scrollHeight;
    if (this.top === 0) {
      this.isAtBottom = false;
    }
    if (this.top > this.scrollHeight - this.offSetHeight - 1) {
      this.isAtBottom = true;
    }
  }
}
