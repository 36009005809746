import { Injectable } from "@angular/core";
import { AuthService } from "@clp/auth";
import { Observable } from "rxjs";
import { first, map, tap } from "rxjs/operators";

export class WellKnownRole {
  public static readonly globalAdmin = 'ConsilioId-Admin-Root';
  public static readonly support = 'ConsilioId-Admin-Helpdesk';
  public static readonly betaUser = 'ConsilioId-User-BetaAccess';
}

export class RoleGlobals {
  public static readonly allRoles: string[] = [
    WellKnownRole.globalAdmin,
    WellKnownRole.support
  ]
}

@Injectable({ providedIn: 'root' })
export class RoleService {

  constructor(private asvc: AuthService) {

  }

  isValidRole(role: string) {
    return RoleGlobals.allRoles.includes(role);
  }

  public hasAnyRole$(allowedRoles: string[]): Observable<boolean> {
    return this.asvc.userData$.pipe(
      map(result => {
        const userDataRole = result.userData?.role;
        const roles: string[] = Array.isArray(userDataRole) ? userDataRole : [
          userDataRole
        ];

        for (const role of roles) {
          if (allowedRoles.includes(role)) {
            return true;
          }
        }

        return false;
      })
    );
  }

  public userRoles$() : Observable<string[]> {
    return this.asvc.userData$.pipe(
      map(result => {
        const userDataRole = result.userData?.role;
        const roles: string[] = Array.isArray(userDataRole) ? userDataRole : [
          userDataRole
        ];

        return roles;
      })
    )
  }
}
