<ng-template #routerLink let-item="item">
  <li class="list-group-item" [ngbTooltip]="item.toolTip" placement="right" [openDelay]="toolTipOpenDelay"
    routerLinkActive="active" [routerLink]="item.routerLink">
    <fa-icon [icon]="item.icon"></fa-icon>
  </li>
</ng-template>

<ul class="list-group text-light" [style.flex-direction]="listDirection">
  <ng-container *ngFor="let item of items" [ngSwitch]="item.type">
    <div *ngSwitchCase="'spacer'" [ngClass]="getAnyItem(item).getClasses(listDirection)"></div>
    <clp-layout-itembar-item-text *ngSwitchCase="'text'" [item]="item"></clp-layout-itembar-item-text>
    <clp-layout-itembar-item-routerlink *ngSwitchCase="'routerlink'" [item]="getRouterLinkItem(item)">
    </clp-layout-itembar-item-routerlink>
  </ng-container>
</ul>
