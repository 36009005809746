import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export abstract class ItemBarItem {
  abstract get type(): string;
  abstract get name(): string;
}

export class SpacerItemBarItem extends ItemBarItem {
  get type(): string {
    return 'spacer';
  }
  get name(): string {
    return '';
  }

  public size: string = '6';


  public getClasses(direction: string) {
    if(direction == 'row') {
      return 'px-2';
    } else {
      return 'py-2';
    }
  }
}

export class TextItemBarItem extends ItemBarItem {
  constructor(public name: string) {
    super();
  }

  get type(): string {
    return 'text'
  }
}

export class RouterLinkItemBarItem extends ItemBarItem {

  constructor(public name: string, public routerLink: string, public icon: IconDefinition, public disabled: boolean = false, public showLabel: boolean = false) {
    super();
  }

  get type(): string {
    return 'routerlink'
  }

  public get toolTip() { return this.name; }
}
