import { Component, Input, OnInit } from '@angular/core';
import { ItemBarItem } from './layout-itembar-items';

@Component({
  selector: 'clp-layout-itembar-container',
  template: `
<clp-layout-itembar [width]="itemBarWidth" [items]="itemBarItems"></clp-layout-itembar>
<ng-content></ng-content>`
})

export class LayoutItemBarContainer implements OnInit {
  constructor() { }

  @Input() public itemBarItems: ItemBarItem[] = [];
  @Input() public itemBarWidth: number | undefined;

  ngOnInit() { }
}
