import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Scroll } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '../layout';
import { DialogMessageComp } from './dialogs';
import { ScrollToBottomDirective } from './directives/scroll-to-bottom.directive';
import { AgLinkCellRenderer } from './grid/link-cell-renderer';
import { NotificationService } from './notification.svc';
import { OverlayLoadingComp } from './overlays/loading.comp';
import { PartsModule } from './parts/parts.m';
import { UContainerComp } from './u-container.comp';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    FontAwesomeModule,
    NgbModalModule,
    PartsModule
  ],
  exports: [
    UContainerComp,
    PartsModule,
    AgLinkCellRenderer,
    ScrollToBottomDirective,
    OverlayLoadingComp
  ],
  declarations: [
    UContainerComp,
    AgLinkCellRenderer,
    ScrollToBottomDirective,
    DialogMessageComp,
    OverlayLoadingComp
  ],
  providers: [
    NotificationService
  ],
})
export class UCommonModule { }
