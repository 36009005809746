import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class AppSvc {
  _configured: boolean = false;
  constructor(private router: Router) {
    this.readConfigured();
  }

  readConfigured(){
    this._configured = sessionStorage.getItem('configured') === `${true}`;
  }

  public get isDevModeEnabled(): boolean {
    return !environment.production;
  }

  public get isSystemConfigured$(): Observable<boolean> {
    return of(this._configured);
  }

  public redirectAfterConfigure() {
    sessionStorage.setItem('configured',`${true}`);
    this.readConfigured();
    this.router.navigateByUrl("/u");
  }
}
