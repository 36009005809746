import { Injectable } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { DialogMessageComp } from "./dialogs";

@Injectable({providedIn: 'root'})
export class NotificationService {

  constructor(private ngbModal: NgbModal) {

  }

  private get defaultOptions(): NgbModalOptions {
    return {
      container: 'clp-layout',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      animation: true
    }
  }

  public publishRudimentaryAlert(message: string) {
    alert(message);
  }

  public openMessage(message: string, title?: string) {
    const modal = this.ngbModal.open(DialogMessageComp, this.defaultOptions);
    const comp = <DialogMessageComp>modal.componentInstance;
    comp.title = title ?? 'Message';
    comp.message = message;
    return modal;
  }

  public openCustomWithDefaults(comp: any) {
    const mref = this.ngbModal.open(comp, this.defaultOptions);
    return mref;
  }
}
