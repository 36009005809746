import { Component, HostBinding, OnInit } from '@angular/core';
import { AssetPaths } from '@clp/asset-paths';
import { ConfigService } from '@clp/config';
import { RoleService, WellKnownRole } from '@clp/i-perm';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from '../auth';
import { AuthUtil } from '../auth/auth.util';
import { LayoutIcons } from './layout-icons';

@Component({
  selector: 'clp-layout-topbar',
  templateUrl: './layout-topbar.comp.html'
})

export class LayoutTopbarComp implements OnInit {
  @HostBinding('class') public readonly classes = 'navbar navbar-dark bg-consilio-blue';

  public iconNotifications = LayoutIcons.navNotifications;
  public brandImgSrc = encodeURI(AssetPaths.Brand);
  public brandTextSrc = encodeURI(AssetPaths.BrandTextSvg72);

  public userDisplayName = '';
  public userEmail = '';
  public userIcon = faUser;

  public isMenuCollapsed = true;
  public isProfileEnabled = false;

  constructor(private authSvc: AuthService, private roleSvc: RoleService, public configSvc: ConfigService) {
    authSvc.userData$.pipe(
      tap(result => {
        const s = result.userData;
        this.userDisplayName = AuthUtil.getName(s);
        this.userEmail = AuthUtil.getFieldAsScalar(s, 'email');
        if(!this.userEmail || '' === this.userEmail) {
          this.userEmail = AuthUtil.getFieldAsScalar(s, 'upn');
        }
      })
    ).subscribe();

    roleSvc.hasAnyRole$([WellKnownRole.betaUser]).pipe(
      filter(x => x),
      tap(x => {
        this.isProfileEnabled = true;
      })
    ).subscribe();
  }

  public get loggedIn() {
    return this.authSvc.isAuthenticated$.pipe(
      map(x => x.isAuthenticated)
    );
  }

  ngOnInit() { }

  public onClickLogout() {
    this.authSvc.logout();
  }
}
