import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterTaglineSupportCmp } from './footer-tagline-support.cmp';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    FooterTaglineSupportCmp
  ],
  declarations: [
    FooterTaglineSupportCmp
  ],
  providers: [],
})
export class PartsModule { }
