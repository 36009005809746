<a class="navbar-brand" aria-labelledby="navbar-brand-text">
  <img [src]="brandImgSrc" id="navbar-brand-avatar" />
  <img [src]="brandTextSrc" id="navbar-brand-text" />
</a>
<ul class="navbar-nav ms-auto" *ngIf="loggedIn | async">
  <li class="nav-item nav-item-user-dropdown" ngbDropdown display="dynamic" placement="bottom-end">
    <a class="nav-link pe-3" tabindex="0" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle id="navUserDropDown" role="button">
      <fa-icon [icon]="userIcon"></fa-icon>
      <span>{{userDisplayName}}</span>
    </a>
    <div ngbDropdownMenu aria-labelledby="navUserDropDown" class="dropdown-menu ps-2 pt-2">
      <div class="px-2 pb-2 small">
        {{userEmail}}
      </div>
      <hr class="mt-0 mb-2" />
      <a ngbDropdownItem *ngIf="isProfileEnabled" routerLink="/u/profile">Profile</a>
      <a ngbDropdownItem (click)="onClickLogout()">Logout</a>
    </div>
  </li>
</ul>
