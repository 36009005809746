import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { RoleGlobals, RoleService } from './role.svc';

@Injectable({ providedIn: 'root' })
export class RoleRootGuard  {
  constructor(private roleSvc: RoleService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.roleSvc.hasAnyRole$(RoleGlobals.allRoles).pipe(
      map(result => {
        if(!result) {
          return this.router.createUrlTree(['/unauthorized'], {queryParams: {
            reason: 'no-matching-roles'
          }});
        }

        return result;
      })
    );
  }
}
