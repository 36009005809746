import { Component } from "@angular/core";

@Component({
  selector: 'ag-link-cell-renderer',
  template: `
  <div [ngStyle]="ngStyle">
    <a href="#" class="text-primary-alt" (click)="$event.preventDefault();onClick()">{{params.label}}</a>
  </div>`
})
export class AgLinkCellRenderer {

    public params: any;
    public ngStyle = {
      textAlign: 'center',
      fontWeight: '400'
    }

    constructor() {
    }

    agInit(params: any): void {
        this.params = params;
        if (!this.params.action) {
            throw new Error('Missing action parameter for ActionCellRendererComponent');
       }

       if(this.params.labelFn) {
         params.label = params.labelFn(params);
       }

       if(this.params.textAlign) {
         this.ngStyle.textAlign = this.params.textAlign;
       }

       if(this.params.fontWeight) {
         this.ngStyle.fontWeight = this.params.fontWeight;
       }
   }

   onClick(): void {
       this.params.action(this.params);
   }
}
