import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clp-footer-tagline-support',
  template: `
<div class="col-12 col-sm-8 offset-sm-2">
  <hr class="my-4 w-50"/>
</div>
<div>
  <p class="text-muted small" i18n>For additional assistance, HelpDesk is available at:<br/>
  <a href="mailto:helpdesk@consilio.com">helpdesk&#64;consilio.com</a><br><a href="tel:+1-312-638-3200">+1-312-638-3200</a> (US) or <a href="tel:+44 203 808 9988">+44 203 808 9988</a> (UK)</p>
</div>`
})

export class FooterTaglineSupportCmp implements OnInit {
  constructor() { }

  ngOnInit() { }
}
