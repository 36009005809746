import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { RoleService } from '.';

@Injectable({providedIn: 'root'})
export class RoleGuard  {
  constructor(private roleSvc: RoleService, private router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const roles: string[] = route.data.roles;
    if(!roles || roles.length == 0) {
      return true;
    }

    return this.roleSvc.hasAnyRole$(roles).pipe(
      map(result => {
        if(!result) {
          return this.router.createUrlTree(['u','unauthorized'], { queryParams: {
            reason: 'required-role-missing'
          }});
        }

        return true;
      })
    );
  }
}
