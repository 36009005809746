import { Component } from '@angular/core';
import { AppSvc } from '@clp/app.svc';
import { RoleGlobals, RoleService } from '@clp/i-perm';
import { filter, take, tap } from 'rxjs';
import { LayoutIcons } from './layout-icons';
import { ItemBarItem, RouterLinkItemBarItem } from './layout-itembar-items';

export class PrimaryRouteTitles {
  public static readonly home = 'Home';
  public static readonly tools = 'Tools';
  public static readonly settings = 'Settings';
  public static readonly dev = 'Dev';
  public static readonly support = 'Support';
}

@Component({
  selector: 'clp-layout',
  templateUrl: './layout.comp.html'
})

export class LayoutComp {

  constructor(private appSvc: AppSvc, private roleSvc: RoleService) {
    roleSvc.hasAnyRole$(RoleGlobals.allRoles).pipe(
      filter(hasRoles => !!hasRoles),
      take(1),
      tap(x => {
        this.leftbarItems.push(
          new RouterLinkItemBarItem(PrimaryRouteTitles.tools, 'tools', LayoutIcons.tools, false),
          new RouterLinkItemBarItem(PrimaryRouteTitles.settings, 'settings', LayoutIcons.settings, true),
          new RouterLinkItemBarItem(PrimaryRouteTitles.support, 'support', LayoutIcons.support, true)
        );
      })
    ).subscribe();
  }

  public leftbarItems: ItemBarItem[] = [
    new RouterLinkItemBarItem(PrimaryRouteTitles.home, 'landing', LayoutIcons.dashboard),
  ];
}
