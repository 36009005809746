import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutItemBarContainer } from './layout-itembar-container.comp';
import { LayoutItemBarComp, RouterLinkItemBarItemComp, TextItemBarItemComp } from './layout-itembar.comp';
import { LayoutTopbarComp } from './layout-topbar.comp';

import { LayoutComp } from './layout.comp';
import { ClpLayoutService, CLP_LAYOUT_SERVICE } from './layout.svc';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FontAwesomeModule,
  ],
  exports: [
    LayoutComp,
    LayoutItemBarComp,
    LayoutItemBarContainer,
    LayoutTopbarComp,
  ],
  declarations: [
    LayoutComp,
    LayoutTopbarComp,
    LayoutItemBarComp,
    TextItemBarItemComp,
    RouterLinkItemBarItemComp,
    LayoutItemBarContainer
  ],
  providers: [{
    provide: CLP_LAYOUT_SERVICE,
    useClass: ClpLayoutService,
  }],
})
export class LayoutModule { }
