import { Component, OnInit } from '@angular/core';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'clp-dialog-message',
  template: `
<div class="disclaimer-title bg-primary-alt text-light py-2 px-3 d-flex">
  <div>
    <fa-icon [icon]="titleIcon"></fa-icon>
    <span>{{title}}</span>
  </div>
</div>
<div class="disclaimer-body pt-4 px-2 pb-2 ms-1">
  <div>
    <pre
    scrollToBottom #scrollToBottom="scrollToBottom"
    style="white-space: pre-line; max-height: 250px;overflow-y: scroll; overflow-x: hidden; word-break: keep-all; word-wrap: normal;">
    {{message}}
    </pre>
  </div>
  <div class="disclaimer-actions border-top pt-2">
    <button type="button" class="btn btn-primary-alt" (click)="onOkClick()" i18n>Ok</button>
  </div>
</div>

`
})

export class DialogMessageComp implements OnInit {

  public title!: string;
  public titleIcon = faInfo;
  public message!: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() { }

  public onOkClick() {
    this.activeModal.close('ok');
  }
}
