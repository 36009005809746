import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'clp-overlay-loading',
  template: `
  <div class="bg"></div>
  <div class="d-flex flex-column align-items-center align-self-center flex-grow-1">
    <span class="loader"></span>
    <div class="mt-3" i18n>Please wait while we retrieve the data.</div>
  </div>`,
  styles: [
    ':host { position: absolute; top: 0; right: 0; left: 0; bottom: 0; display: flex; }',
    `.bg {
      backdrop-filter: blur(2px);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1000;
    }`,
    '.bg + div { z-index: 1001; }'
  ],
})
export class OverlayLoadingComp implements OnInit {
  constructor() {}

  @Input() public loadText: string = '';

  ngOnInit() {}
}
